<template>
  <div id="machine_schedule_manage" class="machine-schedule-manage">

    <div ref="timeline_filter" class="timeline-filter">
      <div class="filter__item filter__search">
        <v-text-field 
          class="filter-process rounded-0"
          label="품명을 입력하세요"
          v-model="filter.program"
          height="34"
          @keyup.enter="search"
          hide-details clearable
        ></v-text-field>
        <v-btn 
          depressed dark
          class="process-search filter-fn-btn rounded-0"
          :class="{ 'mac': is_mac }"
          @click='searchProgram'
        >검색</v-btn>
      </div>
      <div class="filter__item filter__machine">
        <select 
          name="mid_select"
          class="timeline-mid" 
          v-model="filter.period.id"
        >
          <option v-for="mid in mids" :key="mid.id" :value="mid.id" >{{mid.name}}</option>
        </select>
      </div>

      <div class="filter__period filter__item">
        <div class="calendar-date">
          <dayPicker 
            v-if="filter.period.value != 3 && filter.period.value != 0 " 
            :type="filter.period.type" 
            :preset="filter.period.day" 
            :title="'날짜 선택'"
            :border="true"
            :presetIcon="['append', false, false]"
            :fieldHeight="33"
            :clickPick="true"
            @picked="setDay"
          ></dayPicker>
          <datePicker 
            v-else-if="filter.period.value==3" 
            :preset="filter.period.date" 
            :fieldHeight="33"
            :border="true"
            :title="'날짜 선택'"
            :presetIcon="['append', false, false]"
            @picked="setDate"
          ></datePicker>
        </div>
        <v-btn-toggle 
          class="calendar-type"
          :class="{ 'mac': is_mac }"
          v-model="filter.period.value" 
          mandatory tile
        >
          <v-btn class="today">오늘</v-btn>
          <v-btn class="day">일</v-btn>
          <v-btn class="month">월</v-btn>
          <v-btn class="period">기간</v-btn>
        </v-btn-toggle>
      </div>
    </div>

    <div ref="content" class="timeline-content">
      <div ref="timeline_header" id="timeline_header" class="timeline__header">
        <div class="timeline-title">
          <v-btn class="prev" 
            v-if="filter.period.value != 3" 
            fab small depressed text
            @click='prevCalendar' 
          >
            <v-icon size="32">mdi-chevron-left</v-icon>
          </v-btn>
          <p class="title-date">
            <template v-if="filter.period.value != 3">
              {{ filter.period.value == 2 ? filter.period.day.substr(0, 7) : filter.period.day }}
            </template>
            <template v-else>
              {{ filter.period.date.start }} ~ {{ filter.period.date.end }}
            </template>
          </p>
          <v-btn 
            class="next" 
            v-if="filter.period.value != 3" 
            fab small depressed text
            @click='nextCalendar' 
          >
            <v-icon size="32">mdi-chevron-right</v-icon>
          </v-btn>
        </div>
        <!-- <div class="view_mode">
          <v-btn-toggle v-model="timeline.type">
            <v-btn class="timeline_mode"  small><v-icon>mdi-chart-timeline</v-icon></v-btn>
            <v-btn class="table_mode" small><v-icon>mdi-file-table</v-icon></v-btn>
          </v-btn-toggle>
        </div> -->
      </div>
      <div class="timeline-graph graph-area">
        <apexchart
          ref="timeline"
          type="rangeBar"
          class="timeline"
          :height="timeline.height"
          :options="timeline_options"
          :series="copyarray"
        >
        </apexchart>
      </div>
    </div>
    <loading 
      id="loading"
      class='loading' 
      :active.sync="visible" 
      :can-cancel="true" 
      color="#48627b" 
      loader="dots" 
      :is-full-page="full" 
    ></loading>
  </div>
</template>

<script>
import moment from "moment";
import dayPicker from '../components/dayPicker.vue';
import datePicker from '../components/datePicker.vue';
import apexchart from 'vue-apexcharts';
import Loading from 'vue-loading-overlay';

export default {
  components: {
    loading : Loading ,
    dayPicker,
    datePicker,
    apexchart,
  },
  data() {
    return {
      copyarray:[],
      filter: {
        program: null,
        period: {
          value: null,
          id:'%',
          type: 'date',
          day:moment().format('YYYY-MM-DD'),
          date:{
            start: moment().format('YYYY-MM-DD'),
            end: moment().format('YYYY-MM-DD'),
          }
        },
      },
      today: moment().format('YYYY-MM-DD'),
      timeline: {
        height: 300,
        type: 0,
        trimed: {},
        mids: [],
      },
      mids:[ ],
      visible:false,
      timeline_options: {
        noData: {
          text: 'NoData',
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: {
            color: undefined,
            fontSize: '14px',
            fontFamily: undefined
          }
        },
        chart: {
          type: 'rangeBar',
          height: '300',
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: '50%',
            rangeBarGroupRows: true,
          }
        },
        fill: {
          type: 'solid',
        },
        xaxis: {
          type: 'datetime',
          labels: {
            datetimeUTC:false,
            datetimeFormatter: {
              year: 'yyyy',
              month: 'yy/MM', 
              day: 'MM/dd',
              hour: 'HH:mm',
            },
            style: {
              fontSize: '14px',
            },
          },
        },
        yaxis: {
          labels: {
            align: 'left',
            style: {
              fontSize: '16px',
            }
          }
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: true,
          offsetX: 24,
          formatter: function(val, opt) {
            let label = opt.w.config.series[opt.seriesIndex].name;
            return label;
          }
        },
        tooltip: {
          enabled: true,
          custom: function({series, seriesIndex, dataPointIndex, w}){
            const data = w.config.series[seriesIndex];
            const program = data.name;
            const start = data.data[0].y[0];
            const end = data.data[0].y[1];

            let tooltip = '';
            tooltip += '<div class="tooltip-schedule">';
            tooltip += '<p class="tooltip-title">' + program + '</p>';
            tooltip += '<p class="tooltip__content">';
            tooltip += moment(start).format('YYYY-MM-DD HH:mm:ss')
            tooltip += ' ~ ';
            tooltip += moment(end).format('YYYY-MM-DD HH:mm:ss')
            tooltip += '</p>';
            tooltip += '</div>';
            return tooltip;
          }
        },
        grid: {
          show: true,
          xaxis: {
            lines: {
              show: true,
            }
          }
        },
        colors: [ 
          ({seriesIndex, dataPointIndex, value, w}) => {
            const data = w.config.series[seriesIndex];
            if(data.data.length != 0){
              var mid;
              data.data.length > 0 ? mid = data.data[0].x : undefined;
              const program = data.name;
              if( program == undefined) return '#999999'
              const key = data.data[0].y[0];
              const mid_i = this.timeline.mids.indexOf(mid);
              const colors = this.timeline.trimed[mid];
              if( colors == undefined )return this.colorchip[0][0] ;
              const color_i = colors.indexOf(program + '-' + key);
              const first = mid_i % this.colorchip.length;
              const second = color_i % this.colorchip[first].length;
              return this.colorchip[first][second];
            }
           
          }
        ],
      },
      timeline_data: [],
      full:true,
    };
  },
  mounted() {
    this.selectMid();
    this.getGraphHeight();
    window.addEventListener('resize', this.animationFrame(this.getGraphHeight));
  },
  destroyed() {
    window.removeEventListener('resize', this.animationFrame);
  },
  computed: {
    is_mac() {
      return this.$is_mac;
    },
    colorchip() {
      return this.$colorchip;
    }
  },
  methods: {
    setDay(val){
      const filter = this.filter;
      if( filter.period.type == 'date' && val != this.today ) { filter.period.value = 1 }
      if( filter.period.value == 2){ val=val.substr(0,7)}
      filter.period.day = val;
      this.getProgram(val,val);
    },
    selectMid(){
      this.$http.APS.get('/idle/key').then(r=>{
        this.mids=r.data;
        this.mids.unshift({id:'%',name:'전체'})
      })
    },
    searchProgram(){
      if(this.filter.period.value==3){
        this.setDate(this.filter.period.date);
      }else{
        this.setDay(this.filter.period.day);
      }
    },
    async prevCalendar(){
      const filter = this.filter;
      const period = filter.period;

      if( period.value == 2 ){
        period.day = moment(period.day).add(-1,'month').format('YYYY-MM');
      }else{
        period.day = moment(period.day).add(-1,'days').format('YYYY-MM-DD');
      }
      await this.setDay(period.day);
    },
    async nextCalendar(){
      const filter = this.filter;
      const period = filter.period;

      if( period.value == 2 ){
        period.day = moment(period.day).add(1,'month').format('YYYY-MM');
      }else{
        period.day = moment(period.day).add(1,'days').format('YYYY-MM-DD');
      }
      await this.setDay(period.day);
    },
    setDate(val){
      const period = this.filter.period;
      period.date.start = val.start;
      period.date.end = val.end;
      this.getProgram(val.start,val.end);
    },
    getGraphHeight() {
      const graph_area = document.querySelector('.graph-area');
      let area_height = parseInt(getComputedStyle(graph_area).height);
      const except = {
        padding: 24,
        extra: 15,
      };
      this.timeline.height = area_height - 5 - except.padding - except.extra;
    },
    async getProgram(start,end){
      var filter=this.filter.period;
      this.copyarray = [];
      this.timeline_data=[];
      var map = await {program:this.filter.program,mkey:filter.id,startTime:start,endTime:end};
      this.visible = true
      await this.$http.APS.post('/machine/',map).then(async r=>{
        for(let i=0; i<r.data.length; i++){
          this.timeline_data.push(
            { name: r.data[i].name,
              data: [
                  {
                   x: r.data[i].mid,
                   y: [
                      new Date(r.data[i].start).getTime(),
                      new Date(r.data[i].end).getTime(),
                    ]
                },
              ]
          })
        }
        this.getSchedule(start,end);
      });
    },
    async getSchedule(start,end){
      var filter=this.filter.period;
      var map = await {program:this.filter.program,mkey:filter.id,startTime:start,endTime:end};
      await this.$http.APS.post('/machine/sche',map).then(r=>{
        for(let i = 0; i < r.data.length; i++ ){
          this.timeline_data.push({
            name: r.data[i].product_name,
            data:[
              {
                x: r.data[i].name,
                y:[
                    new Date(r.data[i].start).getTime(),
                    new Date(r.data[i].end).getTime(),
                ]  
              }
            ]

          })
        }
        this.copyarray = this.timeline_data ; 
        this.setProgramGroup(this.timeline_data);
        this.visible = false;
      })
    },
    setProgramGroup(data) {
      const tl = this.timeline;
      let group = {};
      for( let i = 0; i < data.length; i++ ) {
        const item = data[i]
        const mid = item.data[0].x;
        const name = item.name;
        const key = item.data[0].y[0];
        if( group[mid] == undefined ) { group[mid] = []; }
        group[mid].push(name + '-' + key);
      }
      tl.mids = Object.keys(group);
      tl.trimed = group;
      //  this.$refs.timeline.updateSeries([{data:this.timeline_data}]);
    },
    animationFrame(cb) {
      let rAftimeout = null;
      return function() {
        if( rAftimeout ) {
          window.cancelAnimationFrame(rAftimeout);
        }
        rAftimeout = window.requestAnimationFrame(function() { cb(); });
      };
    },
  },
  watch: {
    'filter.period.value': function(newV, oldV) {
      const value = ['date', 'date', 'month']
      const type = value[newV];
      const period = this.filter.period;

      if( newV == 0 || newV == 1 ) {
        // 오늘, 일로 변경
        period.type = 'date';
        period.day = this.today;
        this.setDay(this.today);
        return;
      } else {
        const start = moment(period.day).startOf('month').format('YYYY-MM-DD');
        const end = moment(period.day).endOf("month").format('YYYY-MM-DD');
        period.day = start.substr(0, 7);
        period.date.start = start;
        period.date.end = end;
        period.type = 'month';
        this.getProgram(start, end);
      }
    },
    'filter.period.id': function(v) {
      if( this.filter.period.value == 3 ){
        this.getProgram(this.filter.period.date.start, this.filter.period.date.end);
      }else{
        this.getProgram(this.filter.period.day, this.filter.period.day);
      }
    },
  },
}
</script>

<style lang="scss" scoped>


  .machine-schedule-manage {
    @extend .flex_column;

    height: 100%;

    .timeline-filter {
      @extend .common-filter;

      justify-content: flex-start;
      padding: 32px 64px !important;

      .filter__search {
        margin-right: 24px;

        .filter-process {
          @extend .no-bt-border-field;

          border-bottom: 1px solid $main_gray;
        }

        .process-search {
          width: 55px;
          height: 35px;
          margin-left: 8px;
          font-family: $ptd !important;
          font-weight: 500;
          font-size: 16px;
          background-color: $main_gray;

          &.mac {
            padding-top: 3px;
          }
        }
      }

      .filter__machine {
        
        .timeline-mid {
          @extend .common-select;
        }
      }

      .filter__period {
        align-self: flex-end;
        margin-left: auto;

        .calendar-type {
          margin-left: 16px;
        }
      }
    }

    .timeline-content {
      @extend .flex_column;

      justify-content: stretch;
      width: 100%;
      flex: 1 1 auto;
      overflow: hidden;

      .timeline__header {
        @extend .flex_row;

        justify-content: center;
        flex: 0 0 auto;

        .timeline-title {
          @extend .flex_row;

          margin-bottom: 24px;

          .title-date {
            font-size: 32px;
            padding-top: 2px;
            font-family: $ptd !important;
            color: $main_gray;
          }
        }
      }

      .timeline-graph {
        flex: 1 1 0;
        width: 100%;
        padding: 0 64px 24px;
        overflow: hidden;
      }
    }

    // machine schedule rangebar(timeline) tooltip
    ::v-deep .tooltip-schedule {
      @extend .tooltip--black-theme;
    }

    ::v-deep #loading {
      @extend .flex_row;

      justify-content: center;
      width: 100%;
      height: 100%;
      position: fixed;
      background: rgba(255, 255, 255, 0.5);

      .vld-icon {
        display: inline-block;
      }

    }

  }

</style>
<template>
  <div class="date_picker">

    <!-- 기간 선택 -->
    <div class="period_select" v-if="period.active" :class="{ 'mac': is_mac }">
      <v-btn-toggle class="period_btns" v-model="period.value" tile>
        <v-btn text height="40" class="all" value="all">전체</v-btn>
        <v-btn text height="40" class="today" value="today">당일</v-btn>
        <v-btn text height="40" class="month" value="month1">1개월</v-btn>
        <v-btn text height="40" class="month2" value="month2">2개월</v-btn>
        <v-btn text height="40" class="month3" value="month3">3개월</v-btn>
      </v-btn-toggle>
    </div>

    <p class="date_picker__title" v-if="title != ''">
      {{ title }}
    </p>
    
    <p class="start_picker picker" @click.stop="openStartCalendar" @mouseover='over'>
      <v-text-field 
        v-model="start.date"
        class="rounded-0"
        :class="{ 'day-picker--border': border, 'day-picker--prepend': style.icon.prepend }"
        label="시작 날짜"
        :disabled='dis'
        :height="fieldHeight"
        readonly hide-details solo flat dense light
      >
        <v-icon 
          :slot="style.icon.inner ? 'prepend-inner' : 'prepend'" 
          v-if="style.icon.prepend"
          size="18"
        >$pickerCalendar</v-icon>
        <v-icon 
          :slot="style.icon.outer ? 'append-outer' : 'append'" 
          v-else
          size="18"
        >$pickerCalendar</v-icon>
      </v-text-field>
      <!-- 선택창 -->
      <v-menu
        v-model="start.open"
        :close-on-content-click="false"
        :activator="start.element"
        :disabled='dis'
        offset-y
      >
        <v-card flat class="start__calendar calendar">
          <v-date-picker
            v-model="start.date"
            locale="ko"
            :max="end.date"
            :title-date-format="titleFormatter"
            :day-format="dayFormatter"
            color="#556b84"
            @click:date="start.open = false"
          ></v-date-picker>
        </v-card>
      </v-menu>
    </p>


    <!-- end picker ~ 까지 묶음 -->
    <template v-if=" !period.active || period.value != 'today'">
      <span class="gap">~</span>
      
      <p 
      class="end_picker picker" 
      @click.stop="openEndCalendar" 
      @mouseover='over'
    >
      <v-text-field
        v-model="end.date"
        class="rounded-0"
        :class="{ 'day-picker--border': border, 'day-picker--prepend': style.icon.prepend }"
        label="종료 날짜"
        :disabled='dis'
        :height="fieldHeight"
        readonly hide-details solo flat dense light
      >
        <v-icon 
          :slot="style.icon.inner ? 'prepend-inner' : 'prepend'" 
          v-if="style.icon.prepend"
          size="18"
        >$pickerCalendar</v-icon>
        <v-icon 
          :slot="style.icon.outer ? 'append-outer' : 'append'" 
          v-else medium
          size="18"
        >$pickerCalendar</v-icon>
      </v-text-field>
      <!-- 선택창 -->
      <v-menu
        v-model="end.open"
        :close-on-content-click="false"
        :activator="end.element"
        :disabled='dis'
        offset-y
      >
        <v-card flat class="end__calendar calendar">
          <v-date-picker
            v-model="end.date"
            locale="ko"
            :max="today"
            :min="start.date"
            :title-date-format="titleFormatter"
            :day-format="dayFormatter"  
            color="#556b84"
            @click:date="end.open = false"
          ></v-date-picker>
        </v-card>
      </v-menu>
    </p>
  </template>

  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'datePicker',
  data() {
    return {
      dis:false,
      period: {
        value: 'today',
        active: this.periodActive,
      },
      start: {
        date: this.preset.start,
        open: false,
        element: null,
      },
      end: {
        date: this.preset.end,
        open: false,
        element: null,
      },
      style: {
        icon: {
          prepend: this.presetIcon[0] == 'prepend' ? true : false,
          inner: this.presetIcon[1],
          outer: this.presetIcon[2],
        },
      },
      today: moment().format('YYYY-MM-DD'),
      date_format: 'YYYY-MM-DD',
    };
  },
  props: {
    preset: Object,
    periodActive: {
      type: Boolean,
      default: false,
    },
    presetIcon: {
      type: Array,
      default() {
        // true ? 'prepend' : 'append' , inner, outer순
        return [ 'prepend', false, false ];
      },
    },
    title: {
      type: String,
      default: '',
    },
    fieldHeight: {
      type: Number,
      default: 30,
    },
    border: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    is_mac() {
      return this.$is_mac;
    }
  },
  methods: {
    over(){
      this.dis=false;
    },
    openStartCalendar(event) {
      this.start.element = event.target;
      requestAnimationFrame(() => requestAnimationFrame(() => this.start.open = true));
    },
    openEndCalendar(event) {
      this.end.element = event.target;
      requestAnimationFrame(() => requestAnimationFrame(() => this.end.open = true));
    },
    datePick(){
      let empty_start = this.start.date == '';
      let empty_end = this.end.date == '';
      if( !empty_start && !empty_end ) {
        let data = {
          start: this.start.date,
          end: this.end.date,
        }
        this.$emit('picked', data);
      }
    },
    titleFormatter(value) {
      let data = value.split('-');
      return data[1] + '월 ' + data[2] + '일';
    },
    dayFormatter(value) {
      value = value.split('-')[2];
      return Number(value);
    },
    getCalcDate(date, value, unit,) {
      return moment(date).add(value, unit).format(this.date_format);
    },
    checkPeriodSame() {

      const is_today = this.end.date == this.today;
      if( this.start.date != this.today || !is_today) {
        this.period.value = undefined;
      }
      const month = this.getCalcDate(this.end.date, -1, 'M');
      const month2 = this.getCalcDate(this.end.date, -2, 'M');
      const month3 = this.getCalcDate(this.end.date, -3, 'M');
      
      if( is_today && this.start.date == month ) {
        this.period.value = 'month1';
      } else if( is_today && this.start.date == month2 ) {
        this.period.value = 'month2';
      } else if( is_today && this.start.date == month3 ) {
        this.period.value = 'month3';
      }
    }
  },
  watch: {
    'start.date': function() {
      this.checkPeriodSame();
      this.datePick();
    },
    'end.date': function() {
      this.checkPeriodSame();
      this.datePick();
    },
    preset(value) {
      this.start.date = value.start;
      this.end.date = value.end;
    },
    'period.value': function(newVal) {
      let format = this.date_format;

      if( newVal == 'all' ) {
        this.$emit('dateRange','0','9',newVal);
        this.dis=true;
        this.start.date=this.today;
        this.end.date=this.today;
      } else if( newVal == 'today' ) {
        this.dis=false;
        this.start.date=this.today;
        this.end.date=this.today;
        this.$emit('dateRange',this.today,this.today,newVal);
      } else if( newVal == 'month1' ) {
        const start = moment(this.today).add(-1, 'M').format(format);
        this.dis=false;
        this.start.date = start;
        this.end.date = this.today;
        this.$emit('dateRange', moment(this.today).add(-1, 'M').format(format),this.today,newVal);
        this.dis=false;
      } else if ( newVal == 'month2' ) {
        const start = moment(this.today).add(-2, 'M').format(format);
        this.dis=false;
        this.start.date = start;
        this.end.date=this.today;
        this.$emit('dateRange',moment(this.today).add(-2, 'M').format(format),this.today,newVal);
      } else if ( newVal == 'month3') {
        const start = moment(this.today).add(-3, 'M').format(format);
        this.dis=false;
        this.start.date = start;
        this.end.date=this.today;
        this.$emit('dateRange',moment(this.today).add(-3, 'M').format(format),this.today,newVal);
      }
    },
  },
  mounted() {
    this.datePick();
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/global.scss';

* { font-family: $ptd; }

::v-deep .period_select {
  @extend .type-btn;

  margin-right: 16px;
}

.date_picker__title {
  font-family: $ptd !important;
  margin-right: 12px;
  font-size: 13px;
  padding-top: 2px;
  line-height: 30px;
}

.date_picker {
  @extend .flex_row;

  z-index: 12;
  
  ::v-deep .picker {
    width: 160px;
    margin-right: 0 !important;
    cursor: pointer;

    .v-text-field {

      &.day-picker--border {
        border: 1px solid $main_gray !important;
      }
      &.day-picker--prepend {
  
        .v-input__slot {
          width: 150px !important;
        }
      }

      .v-input__control {
        min-height: unset !important;
      }
      .v-input__slot {
        padding: 0 8px !important;
        font-size: 14px;
        font-family: $ptd;
      }

      &__slot {

        input {
          cursor: pointer !important;
        }
      }

      .v-input__prepend-outer {
        margin: 7px 0 0 0 !important;
        padding-left: 12px;

        .v-icon {
          padding-top: 2px;
        }
      }
      .v-input__append-inner {

        .v-icon {
          padding-top: 1px;
        }
      }
    }
  }
  .gap {
    display: inline-block;
    margin: 0 4px;
    font-size: 18px;
  }
}

</style>
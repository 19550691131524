<template>
  <div class="day_picker">
    <div 
      v-if="title != ''"
      class="day_picker__title"
    >{{ title }}</div>
    <v-text-field
      v-model="day.data"
      class="day-picker__field rounded-0"
      :class="{ 'day-picker--border': border, 'day-picker--prepend': style.icon.prepend }"
      :label="label"
      @click.stop="openDay"
      :height="fieldHeight"
      readonly hide-details solo flat dense light
    >
      <v-icon
        v-if="style.icon.prepend"
        :slot="style.icon.inner ? 'prepend-inner' : 'prepend'"
        size="18"
        class="prepend-icon"
      >$pickerCalendar</v-icon>
      <v-icon
        v-else
        :slot="style.icon.outer ? 'append-outer' : 'append'"
        size="18"
        class="append-icon"
      >$pickerCalendar</v-icon>
    </v-text-field>
    <v-menu 
      v-model="day.open"
      content-class="picker_wrap"
      :close-on-click="this.clickPick ? true : false"
      :close-on-content-click="false"  
      :activator="day.element"
      :offset-y="offsetY"
      :offset-x="offsetX"
      :top="top"
      :left="left"
      :right="right"
    >
      <v-card>
        <v-date-picker
          v-model="day.data"
          :type="day.type"
          locale="ko"
          @click:date="dayClick"
          @click:month="monthClick"
          color="#556b84"
          :title-date-format="titleFormatter"
          :day-format="dayFormatter"
        >
        </v-date-picker>
        <v-card-actions class="bottom" v-if="!clickPick">
          <v-spacer></v-spacer>
          <v-btn class="cancle" text @click="dayCancle">취소</v-btn>
          <v-btn class="complete" text @click="dayComplete" >확인</v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>

export default {
  name: 'dayPicker',
  data() {
    return {
      day: {
        data: this.preset,
        beforeData: this.preset,
        open: false,
        element: null,
        type: this.type,
      },
      style: {
        icon: {
          prepend: this.presetIcon[0] == 'prepend' ? true : false,
          inner: this.presetIcon[1],
          outer: this.presetIcon[2],
        }
      }
    };
  },
  props: {
    preset: String,
    offsetY: {
      type: Boolean,
      default: true,
    },
    offsetX: {
      type: Boolean,
      default: false,
    },
    top: {
      type: Boolean,
      default: false,
    },
    left: {
      type: Boolean,
      default: false,
    },
    right: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '날짜 선택',
    },
    type: {
      type: String,
      default: 'date',
    },
    clickPick: {
      type: Boolean,
      default: false,
    },
    presetIcon: {
      type: Array,
      default() {
        return [ 'prepend', false, false ];
      },
    },
    fieldHeight: {
      type: Number,
      default: 30,
    },
    title: {
      type: String,
      default: '',
    },
    border: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    openDay(event) {
      this.day.element = event.target;
      this.day.beforeData = this.day.data;
      requestAnimationFrame( () => requestAnimationFrame( () => this.day.open = true ));
    },
    dayCancle() {
      this.day.data = this.day.beforeData;
      this.day.open = false;
    },
    dayComplete() {
      this.day.open = false;
      this.$emit('picked', this.day.data);
    },
    dayClick() {
      if( !this.clickPick ) return;
      this.dayComplete();
    },
    monthClick() {
      if( !this.clickPick ) return;
      const value = this.day.data + '-01';
      this.day.open = false;
      this.$emit('picked', value);
    },
    titleFormatter(value) {
      let data = value.split('-');
      if( this.day.type == 'month' ) {
        return data[1] + '월'; 
      } else {
        return data[1] + '월 ' + data[2] + '일';
      }
    },
    dayFormatter(value) {
      value = value.split('-')[2];
      return Number(value);
    },
    setDate2Month(date) {
      return date.substr(0, 7);
    }
  },
  mounted() { 
  },
  watch: {
    preset(newV) {
      if( this.day.type == 'month' ) { 
        this.day.data = this.setDate2Month(newV);
        return;
      }
      this.day.data = newV;
    },
    type: function(newV, oldV) {
      this.day.type = newV;
      if( oldV == 'month' ) { this.day.data = this.preset; }
    },
  }
}
</script>

<style lang="scss" scoped>


  * { font-family: $ptd; }

  .day_picker {
    @extend .flex_row;

    z-index: 12;

    .day_picker__title {
      font-family: $ptd !important;
      font-size: 13px;
      line-height: 30px;
      padding-top: 2px;
      margin-right: 12px;
    }

    ::v-deep .day-picker__field {
      align-items: center;

      &.day-picker--border {
        border: 1px solid $border_gray !important;
      }
      &.day-picker--prepend {
  
        .v-input__slot {
          width: 150px !important;
        }
      }
      
      .v-input__control {
        min-height: unset !important;
      }
      .v-input__slot {
        padding: 0 12px !important;
        font-size: 14px;
      }
      .v-text-field__slot {

        input {
          padding: 4px 0 2px;
          font-family: $ptd !important;
          cursor: pointer !important;
        }
      }

      .v-input__prepend-outer {
        margin: 0 !important;
        padding-left: 12px;
      }
      .v-input__append-inner {

        .v-icon {
          padding-top: 1px;
        }
      }
    }
  }


  .picker_wrap {
    width: fit-content !important;
  }

  ::v-deep .v-picker {
    width: 100% !important;
  }

  .bottom {
    padding: 8px;
    padding-top: 12px;
    background-color: white !important;

    // .cancle {}
    .complete {
      font-weight: bolder;
      color: cornflowerblue !important
    }
  }
  .v-text-field {
    padding-top: 0 !important;
    margin-top: 0 !important; 
  }
</style>